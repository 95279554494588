<template>
  <div class="erp-window-content-wrapper">
  <lote-extra :loading="loading" />
    <window-content class="e-input-modern size-xs" v-if="!loading">
      <div>
      <buttons-lote-window @refresh="load" />
      </div>
      <e-row class="w-lote">
        <e-col>

          <!--<div class="row erp-row m-t">
            <div class="col">
              <erp-s-field
                  view="lr"
                  label="Descrição:"
                  label-width="110px"
              >
                <erp-input simple-border v-model="lote.descricao" />
              </erp-s-field>
            </div>
          </div>-->

          <e-row class="m-t">
            <e-col>
              <status-lote-select @change="mudarStatusLote" simple-border label-width="110px" label="Status:"
                                  field-view="lr"
                                  v-model="lote.status" :lote="lote"/>
            </e-col>
          </e-row>


          <e-row>
            <e-col>
              <erp-s-field
                  view="lr"
                  label="Cronômetro:"
                  label-width="110px"
              >
                <erp-input type="number" :placeholder="!lote.cronometro ? String(lote.leilao.timerPregao) : ''"
                           simple-border v-model="lote.cronometro"/>
              </erp-s-field>
            </e-col>
            <e-col v-if="lote.numeroString && lote.numeroString !== lote.numero" class="m-l">
              <e-row mr>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Lote:"
                  >
                    <erp-input simple-border v-model="lote.numeroString"/>
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Ordem/LTN:"
                  >
                    <erp-input simple-border type="number" v-model="lote.numero"/>
                  </erp-s-field>
                </e-col>
              </e-row>

            </e-col>
            <e-col v-else class="m-l">
              <erp-s-field
                  view="lr"
                  label="Lote:"
              >
                <erp-input simple-border type="number" v-model="lote.numero"/>
              </erp-s-field>
            </e-col>
          </e-row>

          <e-row v-if="lote.arremate">
            <e-col style="min-width: 320px">
              <erp-s-field
                  view="lr"
                  label="Arrematante:"
                  label-width="110px"
                  :helper="arrematanteHelper"
                  helper-position="lb"
              >
                <arrematante-input @update="updateArrematante" v-model="lote.arremate.arrematante"/>
              </erp-s-field>
            </e-col>
            <e-col class="m-l">
              <erp-input disable simple-border v-model="mock.arrematante.documento" readonly/>
            </e-col>
          </e-row>

          <e-row class="m-t-xs" v-if="lote.arremate">
            <e-col style="min-width: 320px">
              <erp-s-field
                  view="lr"
                  label="Comprador:"
                  label-width="110px"
                  :helper="compradorHelper"
                  helper-position="lb"
              >
                <arrematante-input @update="updateComprador" v-model="lote.arremate.comprador"/>
              </erp-s-field>
            </e-col>
            <div class="col m-l">
              <erp-input disable simple-border v-model="mock.comprador.documento" readonly/>
            </div>
          </e-row>

          <e-row>
            <e-col style="max-width: 280px">
              <e-row class="m-t-sm">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Incremento:"
                      label-width="110px"
                  >
                    <erp-input simple-border v-model.lazy="lote.valorIncremento" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row class="m-t-n-xs">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Avaliação:"
                      label-width="110px"
                  >
                    <erp-input simple-border v-model.lazy="lote.valorAvaliacao" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row class="m-t-n-xs">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Valor de Mercado:"
                      label-width="110px"
                  >
                    <erp-input simple-border v-model.lazy="lote.valorMercado" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row class="m-t-n-xs">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Lance Atual:"
                      label-width="110px"
                      :helper="diferencaSobreMinimoFormated"
                      helper-position="lb"
                      :class="{'green': isMinimoAtingido, 'red': !isMinimoAtingido}"
                  >
                    <erp-input readonly :class="{'input-green': isMinimoAtingido, 'input-red': !isMinimoAtingido}"
                               simple-border v-model.lazy="lanceAtual"/>
                  </erp-s-field>
                </e-col>
              </e-row>

            </e-col>
            <e-col>
              <e-row class="m-t-sm">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Lance Inicial:"
                      icon-help="Valor do Lance Inicial permitido para os arrematantes iniciarem as ofertas"
                      label-width="110px"
                  >
                    <erp-input simple-border v-model.lazy="lote.valorInicial" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row v-if="lote.leilao.instancia > 1" class="m-t-n-xs">
                <e-col style="flex: 1" class="col m-r">
                  <erp-s-field
                      view="lr"
                      label="% 2º Leilão:"
                      label-width="110px"
                  >
                    <erp-input simple-border v-model="cache.porcentagemDesconto" type="number" suffix="%" />
                  </erp-s-field>
                </e-col>
                <e-col class="col" style="max-width: 60px">
                  <e-btn @click="calcula2leilao" label="Calc" class="erp-btn-h-20 full-width" />
                </e-col>
              </e-row>
              <e-row v-if="lote.leilao.instancia > 1" class="m-t-n-xs">
                <e-col class="col">
                  <erp-s-field
                      view="lr"
                      label="Inicial 2º leilão:"
                      label-width="110px"
                      class="font-bold"
                  >
                    <erp-input v-if="!cache.bindDesconto" ref="valorInicial2" simple-border v-model.lazy="lote.valorInicial2" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row v-if="lote.leilao.instancia === 3" class="m-t-n-xs">
                <e-col class="col">
                  <erp-s-field
                      view="lr"
                      label="Inicial 3º leilão:"
                      label-width="110px"
                      class="font-bold"
                  >
                    <erp-input simple-border v-model.lazy="lote.valorInicial3" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row class="m-t-n-xs">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Valor Mínimo:"
                      icon-help="O valor mínimo que o comitente aceita a venda, abaixo deste valor a venda pode ser condicional."
                      label-width="110px"
                  >
                    <erp-input simple-border v-model.lazy="lote.valorMinimo" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row class="m-t-n-xs">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Valor Meta:"
                      label-width="110px"
                  >
                    <erp-input simple-border v-model.lazy="lote.valorMeta" v-money="money"/>
                  </erp-s-field>
                </e-col>
              </e-row>
            </e-col>
<!--            <e-col>
              <e-row>
                <e-col class="m-t-sm">
                  <erp-s-field
                      view="lr"
                      label-width="110px"
                  >
                    <erp-input disable simple-border v-model.lazy="lanceAtual" class="text-positive"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <div v-for="imposto in impostos" class="row erp-row">
                <e-col class="m-t-n-xs">
                  <erp-s-field
                      view="lr"
                      :label="mountImpostoLabel(imposto.imposto)"
                      label-width="110px"
                  >
                    <erp-input disable simple-border :value="'R$ ' + $options.filters.moeda(imposto.valor)"
                               class="text-positive"/>
                  </erp-s-field>
                </e-col>
              </div>
              <e-row>
                <e-col class="m-t-n-xs">
                  <erp-s-field
                      view="lr"
                      label="Total impostos/taxas:"
                      class="font-bold text-black"
                      label-width="110px"
                  >
                    <erp-input readonly simple-border :value="'R$ ' + $options.filters.moeda(totalImposto)"
                               class="text-positive"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              &lt;!&ndash;<div v-for="imposto in taxas" class="row erp-row">
                <div class="col m-t-n-xs">
                  <erp-s-field
                      view="lr"
                      :label="mountImpostoLabel(imposto)"
                      label-width="110px"
                  >
                    <erp-input disable simple-border :value="calcularImpostoIndividual(imposto, ultimoLance)"
                               class="text-positive" />
                  </erp-s-field>
                </div>
              </div>&ndash;&gt;
              <e-row>
                <e-col class="field-font-13 m-t-n-xs">
                  <erp-s-field
                      view="lr"
                      label="Total:"
                      class="font-bold text-black"
                      label-width="110px"
                  >
                    <erp-input readonly simple-border :value="'R$ ' + $options.filters.moeda(total)"
                               class="text-positive"/>
                  </erp-s-field>
                </e-col>
              </e-row>
            </e-col>-->
          </e-row>
        </e-col>
        <e-col class="m-t lote-nav-col">
          <div class="text-right">
            <btn-emissoes-lote ref="emissor" :lote="lote" class="m-r-sm" />
            <btn-acoes-lote :lote="lote"/>
          </div>
          <div class="m-t-lg text-center font-10">
            <div class="text-center lote-num">LOTE <strong>{{ lote.numeroString ? lote.numeroString : (lote.numero || '?') }}</strong></div>
            <div class="lote-pn-details">
              <div class="text-center">ID #{{ lote.id }}</div>
              <div class="text-center">Leilão {{ lote.leilao.codigo }}</div>
              <div class="text-center" v-if="lote.leilao.dataProximoLeilao">{{ lote.leilao.dataProximoLeilao.date|formatDate('dd/MM/yyyy HH:mm') }}</div>
            </div>
            <div class="text-center font-bold m-t-xxs">{{ lote.leilao.descricaoInterna }}</div>
            <div class="text-center font-bold m-t-xs">Comitente: <span class="uppercase text-orange-9_"
                                                                       style="color: #9B8F55">{{
                lote.bem.comitente.pessoa.name
              }}</span>
            </div>
          </div>
          <e-row class="nav-lote flex no-wrap font-11" style="width: 220px; margin: 10px auto 0">
            <e-col class="flex no-wrap items-center">
              <!--<a><u-icon name="chevron-left" type="fa" class="nav-lote-nav" /></a>-->
              <u-btn :disable="!lote.anterior" icon="chevron-left" icon-type="fa" flat round class="nav-lote-nav"
                     @click="navAnterior" v-shortkey="['arrowleft']" @shortkey.native="navAnterior"/>
              <span class="m-l-xs">anterior</span>
            </e-col>
            <e-col class="flex no-wrap items-center justify-end text-right">
              <span class="m-r-xs">próximo</span>
              <u-btn :disable="!lote.proximo" icon="chevron-right" icon-type="fa" flat round class="nav-lote-nav"
                     @click="navProximo" v-shortkey="['arrowright']" @shortkey.native="navProximo"/>
            </e-col>
          </e-row>
          <div class="text-center m-t-n" style="font-size: 9px; color: #999999">navegação rápida entre os lotes</div>
          <div class="text-center m-t-xxs" style="font-size: 9px; color: #999999"><strong>utilize as setas</strong> do
            teclado para passar
          </div>
        </e-col>
      </e-row>
      <erp-tabs @change="changeTab" :active="tabActive">
<!--        <erp-tab-item name="detalhes">Detalhes</erp-tab-item>-->
        <erp-tab-item name="lances">Lances</erp-tab-item>
<!--        <erp-tab-item name="estatistica">Estatísticas do pregão</erp-tab-item>--> <!-- @TODO -->
<!--        <erp-tab-item name="edital">Informações para edital</erp-tab-item>--> <!-- @TODO -->
        <erp-tab-item name="formalizacao">Formalização</erp-tab-item>
        <erp-tab-item name="pagamento">Pagamento</erp-tab-item>
        <erp-tab-item name="site">Definições</erp-tab-item>
        <erp-tab-item name="habilitacao">Habilitação</erp-tab-item>
        <erp-tab-item name="estatistica">Estatísticas</erp-tab-item>
      </erp-tabs>
      <window-body class="font-11" tabs> <!-- style="min-height: 500px;" -->
        <div v-if="tabActive === 'detalhes'" class="wrapper m-t">
          <bem-aba-basico :disable="true" :cache="cache" :bem="lote.bem"/>
        </div>
        <div v-show="tabActive === 'site'" class="wrapper-lg m-t">
          <div>
            <erp-s-field
                view="lr"
                label=""
                label-width="140px"
                class="m-b"
            >
              <erp-checkbox class="flex items-center" v-model="lote.extra.naoHerdarImpostos">
                <label class="no-select font-bold">
                  <span class="m-l-xs">Preencher imposto manualmente para este lote e não herdar impostos do leilão</span>
                </label>
              </erp-checkbox>
            </erp-s-field>
          </div>

          <e-row :key="'i-' + key" v-for="(imposto, key) in lote.impostoFixo">
            <e-col style="max-width: 300px">
              <erp-s-field
                  view="lr"
                  :label="imposto.imposto.nome"
                  label-width="140px"
                  :disabled="!lote.extra.naoHerdarImpostos"
              >
                <erp-input simple-border :disable="!lote.extra.naoHerdarImpostos" v-model="imposto.imposto.valor" class="text-positive"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <!-- TODO -->
            <!--<e-col style="max-width: 500px">
              <erp-s-field
                  view="lr"
                  label="Texto sobre taxas:"
                  label-width="140px"
                  class="wrap label-wrap"
              >
                <div class="m-b-sm">
                  <e-btn label="Gerar automaticamente" @click="gerarTextoTaxas" />
                </div>
                <textarea v-model="lote.textoTaxas" style="resize: vertical" rows="5"
                          class="w-full wrapper-xs erp-input" />
                <div class="font-10 m-t-xs font-thin">Se aplicável ao site</div>
              </erp-s-field>
            </e-col>-->
          </e-row>
          <e-row class="m-t">
            <e-col style="max-width: 400px">
              <erp-s-field
                  view="lr"
                  label="Permitir parcelamento:"
                  label-width="140px"
              >
                <erp-select v-model="lote.permitirParcelamento"
                            :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row>
            <e-col style="max-width: 400px">
              <erp-s-field
                  view="lr"
                  label="Máximo de parcelas:"
                  label-width="140px"
              >
                <erp-input v-model="lote.parcelamentoQtdParcelas" type="number"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row>
            <e-col style="max-width: 400px">
              <erp-s-field
                  view="lr"
                  label="Mínimo de entrada:"
                  label-width="140px"
              >
                <erp-input v-model="lote.parcelamentoMinimoEntrada" type="number" suffix="%"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <!-- TODO: Indices para parcelas -->

          <e-row class="m-t">
            <e-col style="max-width: 400px">
              <erp-s-field
                  view="lr"
                  label="Permitir propostas:"
                  label-width="140px"
              >
                <erp-select v-model="lote.permitirPropostas"
                            :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
              </erp-s-field>
            </e-col>
          </e-row>
        </div>
        <div v-show="tabActive === 'lances'" class="wrapper m-t">
          <div class="m-t-n" v-if="lote.arremate">
            <p class="font-11 font-bold">Detalhes da venda</p>
            <div class="m-t">
              <detalhe-venda :lote="lote"/>
            </div>
          </div>
          <div class="m-t">
            <p class="font-11 font-bold">Lances ({{ lote.lances ? lote.lances.length : 0 }})</p>
            <div class="m-t">
              <historico-lances @reload="() => load()" :lote="lote"/>
            </div>
            <div class="m-t" v-if="lote.lancesAutomaticos">
              <p class="font-11 font-bold">Lances automáticos ({{ lote.lancesAutomaticos ? lote.lancesAutomaticos.length : 0 }})</p>
              <historico-lances-automaticos :lote="lote"/>
            </div>
            <div class="m-t">
              <historico-lances-apagados :lote="lote"/>
            </div>
          </div>
          <div class="m-t">
            <p class="font-11 font-bold">Participação em leilões</p>
            <div class="m-t">
              <historico-leiloes :lote="lote" :leiloes="lote.participacaoLeiloes"/>
            </div>
          </div>
        </div>

        <div v-show="tabActive === 'edital'" class="wrapper m-t">
          Edital temporariamente desativada
        </div>

        <lote-formalizacao v-if="tabActive === 'formalizacao'" class="wrapper" />

        <div v-show="tabActive === 'pagamento'" class="wrapper m-t">
          <info-pagamento-lote :diferenca-sobre-minimo="diferencaSobreMinimo" :lote="lote"
                               :impostos="totalImposto" :total-pagar="total" :total-pagar-leiloeiro="totalLeiloeiro" :total-pago="totalPago" :saldo="saldo"
                               @update="() => this.load()"/>
        </div>
        <estatisticas v-show="tabActive === 'estatistica'" class="wrapper m-t">
          Estatísticas temporariamente desativada
        </estatisticas>
        <lote-habilitacao v-if="tabActive === 'habilitacao'" />
      </window-body>
      <window-footer class="flex content-between">
        <!--<div class="flex items-center justify-center font-11">
          <div class="m-l-xs">
            Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
            status para Em breve.
          </div>
        </div>-->
        <div class="inline-block window-actions text-right self-end col-grow">
          <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
                 @click="$uloc.window.close($root.wid)"/>
          <e-btn :disable="loading || loading2" label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
                 @click="save"
                 v-shortkey="['f2']" @shortkey.native="save"/>
        </div>
      </window-footer>
    </window-content>
    <window-loading v2 :errors="errors" :status="status" v-else/>
  </div>
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox,
  ErpBox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {atualizaLote, findLote as find} from '../../../../domain/leiloes/services/index'
import TipoLeilaoSelect from '../include/TipoLeilaoSelect'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../../../leiloes/components/include/StatusLeilaoSelect'
import ComitenteMultipleSelect from '../include/ComitenteMultipleSelect'
import PatioInput from '../../../patio/helpers/input/PatioInput'
import SistemaTaxacaoSelect from '../include/SistemaTaxacaoSelect'
import StatusLoteSelect from '../include/StatusLoteSelect'
import BtnAcoesLote from '../context/BtnAcoesLote'
import ArrematanteInput from '../../../arrematante/helpers/input/ArrematanteInput'
import BemAbaBasico from '../../../bem/components/include/BemAbaBasico'
import HistoricoLeiloes from '../../../bem/components/include/HistoricoLeiloes'
import DetalheVenda from '../include/lote/DetalheVenda'
import HistoricoLances from '../include/lote/HistoricoLances'
import InfoPagamentoLote from '../include/lote/InfoPagamento'
// import {datePtToEn, datetimeToEn} from '../../../../utils/date';
import {TIPO_PORCENTAGEM} from '@/domain/taxas/helpers/Status'
import windowStatusLote from '../../windows/mudancaStatusLote'
import windowCancelarVenda from '../../../pda/components/apps/arrematante/cancelarVenda'
import * as StatusLote from '../../../../domain/leiloes/helpers/LoteStatus'
import * as StatusPagamento from '../../../../domain/leiloes/helpers/PagamentoStatus'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import LoteMixin from '@/components/leiloes/components/include/LoteMixin'
import BtnEmissoesLote from '@/components/leiloes/components/context/BtnEmissoesLote'
import HistoricoLancesAutomaticos from '@/components/leiloes/components/include/lote/HistoricoLancesAutomaticos'
import HistoricoLancesApagados from "@/components/leiloes/components/include/lote/HistoricoLancesApagados"
import {mockBem} from "@/domain/bem/helpers/Mock"
import LoteExtra from "components/leiloes/components/window/LoteExtra"
import ButtonsLoteWindow from "components/leiloes/components/window/lote/buttons-lote-window"
import LoteFormalizacao from "components/leiloes/components/window/abas/LoteFormalizacao"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import Estatisticas from "components/leiloes/components/include/lote/Estatisticas"
import LoteHabilitacao from "components/leiloes/components/lote/Habilitacao.vue";

let mockLote = {
  id: null,
  codigo: null,
  numero: null,
  descricao: null,
  cronometro: null,
  arremate: {
    arrematante: null,
    comprador: null
  },
  valorAvaliacao: 0.00,
  valorInicial: null,
  valorInicial2: null,
  valorInicial3: null,
  valorMinimo: null,
  valorIncremento: null,
  valorMeta: null,
  lances: null,
  impostos: {
    icms: 0,
    iss: 0,
    comissao: 0,
    taxas: 0
  },
  comitente: null,
  leilao: null,
  textoTaxas: null,
  permitirParcelamento: null,
  parcelamentoQtdParcelas: null,
  parcelamentoMinimoEntrada: null,
  parcelamentoIndices: null,
  permitirPropostas: false,
  videos: [],
  extra: {
    naoHerdarImpostos: false
  }
}

export default {
  name: 'LoteWindow',
  provide: function () {
    return {
      loteContainer: this
    }
  },
  mixins: [LoteMixin],
  directives: {money: VMoney},
  props: ['__lote', 'router', 'action'],
  data() {
    return {
      money: REAL_BRL,
      tabActive: 'lances',
      afterUpdate: false,
      loading: true,
      loading2: false,
      errors: null,
      success: false,
      status: null,
      lote: JSON.parse(JSON.stringify(mockLote)),
      mock: {
        arrematante: {
          id: null,
          apelido: null,
          documento: null
        },
        comprador: {
          id: null,
          apelido: null,
          documento: null
        }
      },
      hasAlteracao: false,
      cache: {
        comitenteSelected: null,
        remocaoSelected: null,
        marcaSelected: null,
        modeloSelected: null,
        porcentagemDesconto: null,
        bindDesconto: false,
        preventDescontoPct: false,
        preventValorInicial2Update: false
      },
      textoTaxasEnableAuto: true
    }
  },
  mounted() {
    this.load()
  },
  computed: {},
  watch: {
    'cache.porcentagemDesconto' (v) {
      return;
      if (this.cache.preventDescontoPct) return
      this.calcula2leilao()
    },
    'lote.valorInicial2' (v) {
      if (this.cache.preventValorInicial2Update) return
      this.$nextTick((() => {
        this.cache.preventDescontoPct = true
        const v1 = convertRealToMoney(this.lote.valorInicial)
        const v2 = convertRealToMoney(this.lote.valorInicial2)
        if (v1 > 0 && v2 > 0) {
          this.cache.porcentagemDesconto = (v2 / v1) * 100
        }
        this.$nextTick(() => {
          this.cache.preventDescontoPct = false
        })
      }))
    }
  },
  methods: {
    /*gerarTextoTaxas () {
      let texto = [] //TODO: Criar template para isto ser configurado
      console.log('Gerar')
      if (this.impostos && this.impostos.length) {
        this.impostos.map(imposto => {
          let tx = imposto.imposto
          let valor
          if (parseInt(tx.tipo) === TIPO_PORCENTAGEM) {
            valor = `${tx.valor}%`
          } else {
            // valor = 'R$ ' + this.$options.filters.moeda(tx.valor || 0)
            valor = 'R$ ' + tx.valor
          }
          texto.push(`${tx.nome}: ${valor}`)
        })
        texto = texto.join('\r\n')
        console.log(texto)
        if (!this.lote.textoTaxas || this.lote.textoTaxas === '') {
          this.lote.textoTaxas = texto
        }
      }
    },*/
    navAnterior() {
      if (this.lote.anterior) {
        this.load(this.lote.anterior.id)
      }
    },
    navProximo() {
      if (this.lote.proximo) {
        this.load(this.lote.proximo.id)
      }
    },
    fomataDocPessoa(v) {
      let doc
      if (Number(v.pessoa.type) === 1) {
        doc = this.$options.filters.formataCpf(v.pessoa.document)
      } else {
        doc = this.$options.filters.formataCnpj(v.pessoa.document)
      }
      return doc
    },
    updateArrematante(v) {
      if (v && v.pessoa && v.pessoa.documento) {
        let doc = this.fomataDocPessoa(v)
        this.mock.arrematante.id = v.id
        this.mock.arrematante.apelido = v.apelido
        this.mock.arrematante.documento = doc
      }
    },
    updateComprador(v) {
      if (v && v.pessoa && v.pessoa.documento) {
        let doc = this.fomataDocPessoa(v)
        this.mock.comprador.id = v.id
        this.mock.comprador.apelido = v.apelido
        this.mock.comprador.documento = doc
      }
    },
    changeTab({tab}) {
      this.tabActive = tab
    },
    save() {
      console.log('Save')
      let data
      try{
        data = JSON.parse(JSON.stringify(this.lote))

        data.valorIncremento = convertRealToMoney(data.valorIncremento)
        data.valorAvaliacao = convertRealToMoney(data.valorAvaliacao)
        data.valorInicial = convertRealToMoney(data.valorInicial)
        data.valorInicial2 = convertRealToMoney(data.valorInicial2)
        data.valorInicial3 = convertRealToMoney(data.valorInicial3)
        data.valorMinimo = convertRealToMoney(data.valorMinimo)
        data.valorMeta = convertRealToMoney(data.valorMeta)
        data.valorMercado = convertRealToMoney(data.valorMercado)

        data.extra = JSON.stringify(data.extra)
      } catch (e) {
        this.alertApiError(null, 'Erro de validação', null, e.message)
        return
      }

      this.loading2 = true
      atualizaLote(this.lote.id, data)
          .then(response => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.afterUpdate = true
                this.load(this.lote.id)
                this.loading2 = false
                // this.success = false
                this.afterUpdate = false
                this.dg()
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            console.log(error)
            this.loading2 = false
            this.alertApiError(error)
          })
    },
    windowStatusLote: windowStatusLote,
    mudarStatusLote() {
      this.$nextTick(() => {
        console.log('Mudança de status de lote ', this.lote.status)
        if (parseInt(this.lote.status) === StatusLote.STATUS_CANCELADO) {
          this.windowCancelarVenda(this.lote)
        } else {
          this.windowStatusLote(this.lote)
        }
      })
    },
    load(id = null) {
      console.log('LOADING')
      if (!this.afterUpdate) {
        this.loading = true
      }
      const propLoteId = this.__lote ? this.__lote.id : null
      id = !id ? propLoteId : id
      this.__lote && find(id)
          .then(response => {
            let data = response.data
            this.$uloc.window.emit(this.$root.wid, 'loaded', data)
            /*if (data.dataEntrada && data.dataEntrada.date) {
              data.dataEntrada = date.formatDate(data.dataEntrada.date, 'DD/MM/YYYY HH:mm')
            }*/
            if (data.arremate) {
              if (data.arremate.arrematante) {
                this.mock.arrematante = data.arremate.arrematante
                this.mock.arrematante.documento = this.fomataDocPessoa(data.arremate.arrematante)
              }
              if (data.arremate.comprador) {
                this.mock.comprador = data.arremate.comprador
                this.mock.comprador.documento = this.fomataDocPessoa(data.arremate.comprador)
              }
            }
            let v1 = data.valorInicial
            let v2 = data.valorInicial2
            data.valorAvaliacao = 'R$ ' + this.$options.filters.moeda(data.valorAvaliacao || 0)
            data.valorMercado = 'R$ ' + this.$options.filters.moeda(data.valorMercado || 0)
            data.valorInicial = 'R$ ' + this.$options.filters.moeda(data.valorInicial || 0)
            data.valorInicial2 = 'R$ ' + this.$options.filters.moeda(data.valorInicial2 || 0)
            data.valorInicial3 = 'R$ ' + this.$options.filters.moeda(data.valorInicial3 || 0)
            data.valorMinimo = 'R$ ' + this.$options.filters.moeda(data.valorMinimo || 0)
            data.valorIncremento = 'R$ ' + this.$options.filters.moeda(data.valorIncremento || 0)
            data.valorMeta = 'R$ ' + this.$options.filters.moeda(data.valorMeta || 0)
            console.log(data.leilao.instancia)
            /*if (data.leilao.instancia > 1) {
              this.cache.porcentagemDesconto = ((v1 - v2) / v1) * 100
            }*/
            /*if (response.data.tipo !== null) {
              this.$nextTick(() => {

              })
            }*/
            // let mock = JSON.parse(JSON.stringify(mockLote))
            // this.lote = Object.assign(mock, data)
            if (data.bem.siteObservacao === null) {
              data.bem.siteObservacao = ''
            }

            if (null === data.permitirParcelamento) {
              data.permitirParcelamento = data.leilao.permitirParcelamento
            }
            if (null === data.parcelamentoQtdParcelas) {
              data.parcelamentoQtdParcelas = data.leilao.parcelamentoQtdParcelas
            }
            if (null === data.parcelamentoMinimoEntrada) {
              data.parcelamentoMinimoEntrada = data.leilao.parcelamentoMinimoEntrada
            }
            if (null === data.permitirPropostas) {
              data.permitirPropostas = data.leilao.permitirPropostas
            }

            if (!data.extra || typeof data.extra !== 'object') {
              data.extra = {naoHerdarImpostos: false}
            }

            if (!data.bem.imovel) {
              data.bem.imovel =  JSON.parse(JSON.stringify(mockBem.imovel))
            }
            if (!data.bem.veiculo) {
              data.bem.veiculo =  JSON.parse(JSON.stringify(mockBem.veiculo))
            }

            this.lote = data
            this.$nextTick(() => {
              this.loading = false
              if (this.action) {
                let action = this.action.split('=')
                if (action[0] === 'window') {
                  this.tabActive = action[1]
                }
              }
              // this.gerarTextoTaxas()
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar o lote, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    windowCancelarVenda: windowCancelarVenda,
    calcula2leilao () {
      this.cache.preventValorInicial2Update = true
      const valorInicial = convertRealToMoney(this.lote.valorInicial)
      console.log(valorInicial)
      this.$nextTick((() => {
        if (valorInicial > 0) {
          const total = valorInicial * (Number(this.cache.porcentagemDesconto) / 100)
          this.cache.bindDesconto = true
          this.lote.valorInicial2 = 'R$ ' + this.$options.filters.moeda(total || 0)
          this.$nextTick(() => {
            this.cache.bindDesconto = false
            this.cache.preventValorInicial2Update = false
          })
        }
      }))
    }
  },
  components: {
    LoteHabilitacao,
    Estatisticas,
    EBtn,
    LoteFormalizacao,
    ButtonsLoteWindow,
    LoteExtra,
    HistoricoLancesApagados,
    HistoricoLancesAutomaticos,
    BtnEmissoesLote,
    ECol,
    ERow,
    InfoPagamentoLote,
    HistoricoLances,
    DetalheVenda,
    HistoricoLeiloes,
    BemAbaBasico,
    ArrematanteInput,
    BtnAcoesLote,
    StatusLoteSelect,
    SistemaTaxacaoSelect,
    PatioInput,
    ComitenteMultipleSelect,
    StatusSelect,
    DefaultAsyncSelect,
    TipoLeilaoSelect,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    ErpLabel,
    // UEditor,
    // URadio
    ErpSelect,
    DatetimeInput,
    ErpCheckbox,
    ErpBox
  }
}
</script>
