<script>
import ECol from "components/layout/components/Col.vue"
import ERow from "components/layout/components/Row.vue"
import {ErpSField} from "@/plugins/uloc-erp"
import SlCheckbox from "@/reuse/input/Checkbox.vue"
import ListaHabilitacoesLotes from "components/leiloes/components/habilitacoes/Lotes.vue";

export default {
  name: "LoteHabilitacao",
  inject: ['loteContainer'],
  provide: function () {
    return {
      page: this
    }
  },
  computed: {
    leilao () {
      return this.loteContainer.lote.leilao
    }
  },
  components: {
    ListaHabilitacoesLotes,
    SlCheckbox,
    ErpSField,
    ERow,
    ECol
  }
}
</script>

<template>
  <div class="lote-habilitacao-tab wrapper">
    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="lr"
            label-width="300px"
            label="Obrigar Habilitação aprovada no lote para lances:"
            icon-help="Se ativo, somente será possível efetuar lances neste lote licitantes cuja habilitação individual neste lote esteja aprovada."
        >
          <sl-checkbox v-model="loteContainer.lote.extra.obrigarHabilitacao"/>
          <div>Necessário estar com Habilitação por Lote e Direito de preferência ativo no leilão</div>
        </erp-s-field>
      </e-col>
    </e-row>
    <lista-habilitacoes-lotes :lote="loteContainer.lote" :inline="true" class="m-t-lg" />
  </div>
</template>
